const solveImageLink = (image, sourceType = 'fluid') => {
  if (image == null || image == '') return null;
  switch (sourceType) {
    case 'fluid':
      return (
        image &&
        image.localFile &&
        image.localFile.childImageSharp &&
        image.localFile.childImageSharp.fluid
      );

    case 'src':
    default:
      return { src: image };
  }
};

export default solveImageLink;
