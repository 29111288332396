// TODO: CHECK USE
// TODO: REFACTORING
import React, { useMemo } from 'react';
import GatsbyImage from 'gatsby-image';
import solveImageLink from '@utils/solveImageLink';

/**
 *
 * @param {*} type.
 */
const ImageWrapper = ({
  type = 'image-gatsby',
  sourceType = 'fluid',
  src,
  ...props
}) => {
  const imageSolved = useMemo(() => {
    return solveImageLink(src, sourceType);
  }, [src, sourceType]);
  if (imageSolved) {
    switch (type) {
      case 'image':
        return <img src={imageSolved.src} {...props} />;

      case 'image-gatsby':
        if (sourceType == 'fluid') {
          props.fluid = imageSolved;
        }
        return <GatsbyImage {...props} />;
    }
  } else {
    return <></>;
  }
};

export default ImageWrapper;
